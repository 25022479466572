var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "link" },
    [
      _vm.isRouterLink
        ? _c(
            "router-link",
            { style: { color: _vm.color }, attrs: { to: _vm.path } },
            [
              _vm._t("default", [_vm._v("{Link text goes here}")]),
              _vm._v(" "),
              _c("span", {
                staticClass: "link__underline",
                style: { backgroundColor: _vm.color },
              }),
            ],
            2
          )
        : !_vm.isClickable
        ? _c(
            "a",
            {
              style: { color: _vm.color },
              attrs: { href: _vm.path, target: "_blank" },
            },
            [
              _vm._t("default", [_vm._v("{Link text goes here}")]),
              _vm._v(" "),
              _c("span", {
                staticClass: "link__underline",
                style: { backgroundColor: _vm.color },
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }