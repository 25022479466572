//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      default: 'filled'
    }
  },
  methods: {
    handleClick: function handleClick() {
      this.$emit('click');
    }
  }
};