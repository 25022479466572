var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-container" }, [
      _c("div", { staticClass: "half-circle-spinner" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/protrainer-spinner.svg"),
            alt: "svg",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }