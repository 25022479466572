import request from '@/utils/request';
export function login(email, password) {
  return request({
    url: '/login',
    method: 'post',
    params: {
      email: email,
      password: password
    }
  });
}
export function getInfo(token, mocked) {
  if (!mocked) {
    return request({
      url: '/user',
      method: 'get'
    });
  } else {
    return Promise.resolve(
      /*
      mockedUser.getInfo({user
        body: {
          token: 'protrainer-token'
        }
      })
      */
    );
  }
}
export function register(firstname, lastname, email, password, password_confirmation) {
  return request({
    url: '/register',
    method: 'post',
    params: {
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password,
      password_confirmation: password_confirmation
    }
  });
}
export function logout(notMocked) {
  if (notMocked) {
    return request({
      url: '/logout',
      method: 'post'
    });
  } else {
    // return Promise.resolve(mockedUser.logout())
  }
}
export function recoverPassword(params) {
  if (!params) throw new Error("Email wasn't provided");
  return request({
    url: '/sendResetLink',
    method: 'post',
    params: params
  });
}
export function resetPassword(params) {
  if (!params) throw new Error("Params weren't provided");
  return request({
    url: '/resetPassword',
    method: 'post',
    params: params
  });
}

/**
 *
 * @returns {Object} fetched users response object
 */
export function fetchUsers() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/user/index',
    method: 'get',
    params: params
  });
}

/**
 *
 * @returns {Object} fetched user response object
 */
export function fetchUser() {
  var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return request({
    url: '/user/show/' + id,
    method: 'get'
  });
}
export function createUser(params, data) {
  return request({
    url: '/user/store',
    method: 'post',
    params: params,
    data: data
  });
}
export function editUser(id, params, data) {
  if (!id && typeof +id !== 'number') {
    throw new Error('No user id was given.');
  }
  return request({
    url: '/user/edit/' + id,
    method: 'post',
    params: params,
    data: data
  });
}
export function updateUser(params, data) {
  return request({
    url: '/user/update',
    method: 'post',
    params: params,
    data: data
  });
}
export function updateProfile(id, params, data) {
  return request({
    url: '/profiles/' + id,
    method: 'post',
    params: params,
    data: data
  });
}
export function updateUserProfile(id, params, data) {
  return request({
    url: '/user/' + id + '/profile',
    method: 'post',
    params: params,
    data: data
  });
}

/**
 *
 * deletes user from database
 * @param {Object} id - user id to delete
 * @returns {Object} created user response object
 */
export function deleteUser(id) {
  return request({
    url: 'user/delete/' + id,
    method: 'delete'
  });
}
export function fetchCoachClient(id, params) {
  if (!id && typeof +id !== 'number') {
    throw new Error('No user id was given.');
  }
  return request({
    url: '/user/' + id + '/clientCoach',
    method: 'get',
    params: params
  });
}
export function fetchCoachRapport(id, params) {
  if (!id && typeof +id !== 'number') {
    throw new Error('No user id was given.');
  }
  return request({
    url: '/user/' + id + '/rapport',
    method: 'get',
    params: params
  });
}
export function all() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/user/all',
    method: 'get',
    params: params
  });
}
export function checkToken(params) {
  if (!params) throw new Error("Params weren't provided");
  return request({
    url: '/token',
    method: 'post',
    params: params
  });
}
export function createStripeAccount(params) {
  return request({
    url: '/user/stripe',
    method: 'post',
    params: params
  });
}
export function retrieveStripeAccount(params) {
  return request({
    url: '/user/stripe/retrieve',
    method: 'get',
    params: params
  });
}
export function addExternalBankAccount(id, params, data) {
  if (!id && typeof +id !== 'number') {
    throw new Error('No user id was given.');
  }
  return request({
    url: "/user/".concat(id, "/add-external-bank-account"),
    method: 'post',
    params: params,
    data: data
  });
}
export function updateDefaultExternalAccount(id, params, data) {
  if (!id && typeof +id !== 'number') {
    throw new Error('No user id was given.');
  }
  return request({
    url: "/user/".concat(id, "/update-default-external-bank-account"),
    method: 'post',
    params: params,
    data: data
  });
}
export function sendIdentityDocument(id, params, data) {
  if (!id && typeof +id !== 'number') {
    throw new Error('No user id was given.');
  }
  return request({
    url: "/user/".concat(id, "/send-identity-document"),
    method: 'post',
    params: params,
    data: data
  });
}
export function checkoutStripe(params) {
  return request({
    url: '/user/checkout',
    method: 'post',
    params: params
  });
}
export function sendMail(params, data) {
  return request({
    url: '/user/sendMail',
    method: 'post',
    params: params,
    data: data
  });
}
export function askReview(params, data) {
  return request({
    url: '/user/review',
    method: 'post',
    params: params,
    data: data
  });
}