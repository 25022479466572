import { render, staticRenderFns } from "./LoadingSpinner.vue?vue&type=template&id=23b874ed&scoped=true&"
var script = {}
import style0 from "./LoadingSpinner.vue?vue&type=style&index=0&id=23b874ed&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b874ed",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/protrainer1/protrainer-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('23b874ed')) {
      api.createRecord('23b874ed', component.options)
    } else {
      api.reload('23b874ed', component.options)
    }
    module.hot.accept("./LoadingSpinner.vue?vue&type=template&id=23b874ed&scoped=true&", function () {
      api.rerender('23b874ed', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/LoadingSpinner.vue"
export default component.exports