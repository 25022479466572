//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    path: {
      type: [String, Object],
      default: ''
    },
    isRouterLink: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#2699fb'
    },
    isClickable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click: function click() {
      this.$emit('click');
    }
  }
};