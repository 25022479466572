import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  withCredentials: false,
  // send cookies when cross-domain requests
  timeout: 20000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  store.dispatch('app/setLoading', true);
  if (store.getters.token) {
    config.headers['Authorization'] = 'Bearer ' + getToken();
  }
  return config;
}, function (error) {
  store.dispatch('app/setLoading', true);
  Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code.
 */
function (response) {
  store.dispatch('app/setLoading', false);
  if (parseInt(response.status) >= 100 && parseInt(response.status) < 400) {
    if ('message' in response.data && response.data.message !== '') {
      Message({
        message: response.data.message,
        type: 'success',
        duration: 3500
      });
    }
    if ('data' in response.data) {
      return response.data.data;
    } else {
      return response.data;
    }
  } else {
    // Wadie : I think this will never happen because all errors are going to error section just below..
    if ('errors' in response.response.data) {
      var errors = response.response.data.errors;
      var message = '';
      for (var key in errors) {
        message += "<li>".concat(errors[key][0], "</li>");
      }
      Message({
        dangerouslyUseHTMLString: true,
        message: "<ul>".concat(message, "</ul>"),
        type: 'error',
        duration: 4500
      });
    } else {
      Message({
        message: response.data.message,
        type: 'error',
        duration: 3500
      });
    }
  }
}, function (error) {
  store.dispatch('app/setLoading', false);
  if (error.response && 'message' in error.response.data) {
    if ('errors' in error.response.data) {
      var errors = error.response.data.errors;
      var message = '';
      for (var key in errors) {
        message += "<li>".concat(errors[key][0], "</li>");
      }
      Message({
        dangerouslyUseHTMLString: true,
        message: "<ul>".concat(message, "</ul>"),
        type: 'error',
        duration: 4500
      });
    } else {
      Message({
        message: error.response.data.message,
        type: 'error',
        duration: 3500
      });
    }
  } else if (error.code === 'ECONNABORTED') {
    Message({
      message: 'Le serveur met trop de temps à répondre. Veuillez réessayer plus tard.',
      type: 'error',
      duration: 3500
    });
  }
  return Promise.reject(error);
});
export default service;