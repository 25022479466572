import "core-js/modules/es6.function.name";
var state = {
  modal: {
    opened: false,
    name: '',
    payload: {}
  }
};
var mutations = {
  OPEN_MODAL: function OPEN_MODAL(state) {
    state.modal.opened = true;
  },
  SET_CURRENT_MODAL: function SET_CURRENT_MODAL(state, currentModal) {
    state.modal.name = currentModal;
  },
  SET_CURRENT_MODAL_PAYLOAD: function SET_CURRENT_MODAL_PAYLOAD(state, payload) {
    state.modal.payload = payload;
  },
  CLOSE_MODAL: function CLOSE_MODAL(state) {
    state.modal.opened = false;
  }
};
var actions = {
  openModal: function openModal(_ref, _ref2) {
    var commit = _ref.commit;
    var name = _ref2.name,
      _ref2$payload = _ref2.payload,
      payload = _ref2$payload === void 0 ? null : _ref2$payload;
    commit('OPEN_MODAL');
    commit('SET_CURRENT_MODAL', name);
    commit('SET_CURRENT_MODAL_PAYLOAD', payload);
  },
  closeModal: function closeModal(_ref3) {
    var commit = _ref3.commit;
    commit('CLOSE_MODAL');
    commit('SET_CURRENT_MODAL', '');
    commit('SET_CURRENT_MODAL_PAYLOAD', {});
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};