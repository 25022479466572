var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _vm._v(" "),
      _c("stripe-modal"),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "spinner" },
            [_c("loading-spinner", { staticClass: "spinner-object" })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }