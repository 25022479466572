import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: function data() {
    return {
      account: {},
      info: {},
      modalInfos: {},
      isVisible: false,
      innerVisible: false
    };
  },
  computed: {
    isDialogVisible: function isDialogVisible() {
      return this.modalInfos.name === 'StripeModal' ? this.modalInfos.opened : false;
    }
  },
  watch: {
    isDialogVisible: function isDialogVisible(newVal) {
      this.isVisible = newVal;
      this.btnHidden = true;
      if (newVal) {
        this.info = this.$store.getters.info;
      }
    }
  },
  created: function created() {
    this.modalInfos = this.$store.state.modals.modal;
  },
  methods: {
    closeModal: function closeModal() {
      this.isVisible = false;
      this.resetForm('stripeForm');
      this.$store.dispatch('modals/closeModal');
    },
    redirectToAccount: function redirectToAccount() {
      this.$router.push({
        name: 'Mon compte'
      });
      this.closeModal();
    }
  }
};