import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/builds/protrainer1/protrainer-app/node_modules/core-js/modules/es6.array.iterator.js";
import "/builds/protrainer1/protrainer-app/node_modules/core-js/modules/es6.promise.js";
import "/builds/protrainer1/protrainer-app/node_modules/core-js/modules/es6.object.assign.js";
import "/builds/protrainer1/protrainer-app/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import _i18n from "./lang"; // internationalization
import "./icons"; // icon
import "./permission"; // permission control
import "./utils/error-log"; // error log

import * as filters from "./filters"; // global filters

import BaseButton from '@/components/BaseButton';
import BaseLink from '@/components/BaseLink';
import VueStripeCheckout from 'vue-stripe-checkout';
import { VTooltip, VPopover } from 'v-tooltip';
Vue.directive('tooltip', VTooltip);
Vue.component('v-popover', VPopover);
Vue.component('base-button', BaseButton);
Vue.component('base-link', BaseLink);

/**
 * If you don't want to use mock-server
 * you want to use mockjs for request interception
 * you can execute:
 *
 * import { mockXHR } from '../mock'
 * mockXHR()
 */

Vue.use(Element, {
  size: Cookies.get('size') || 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.use(VueStripeCheckout);
// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});