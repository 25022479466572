//
//
//
//
//
//
//
//
//
//

import StripeModal from '@/components/StripePopup';
import LoadingSpinner from '@/components/LoadingSpinner';
export default {
  name: 'App',
  components: {
    StripeModal: StripeModal,
    LoadingSpinner: LoadingSpinner
  },
  computed: {
    loading: function loading() {
      return this.$store.state.app.loading;
    }
  }
};