var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    {
      class:
        _vm.type == "filled" ? "filled" : "blank-blue" ? "blank-blue" : "blank",
      on: { click: _vm.handleClick },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }