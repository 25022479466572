export default {
  route: {
    dashboard: 'Tableau de bord'
  },
  navbar: {
    logOut: 'Déconnexion',
    dashboard: 'Tableau de bord'
  },
  login: {
    title: 'Se connecter',
    logIn: 'Connexion',
    username: 'Nom d\'utilisateur',
    password: 'Mot de passe'
  }
};