import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router Modules */
/* import componentsRouter from './modules/components'
import chartsRouter from './modules/charts'
import tableRouter from './modules/table'
import nestedRouter from './modules/nested' */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','coach', 'client']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/loginpro/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/signup',
  component: function component() {
    return import('@/views/signup/index');
  },
  hidden: true
}, {
  name: 'passwordReset',
  path: '/password/reset',
  component: function component() {
    return import('@/views/passwordreset/newpass');
  },
  hidden: true
}, {
  name: 'passwordSet',
  path: '/password/set',
  component: function component() {
    return import('@/views/passwordreset/setpass');
  },
  hidden: true
}, {
  path: '/password/recover',
  component: function component() {
    return import('@/views/passwordreset/email/email');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  hidden: true,
  redirect: '/dashboard'
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export var asyncRoutes = [{
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  meta: {
    roles: ['client', 'coach', 'admin']
  },
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/coach/index');
    },
    name: 'Dashboard',
    meta: {
      title: 'Tableau de bord',
      affix: true,
      icon: 'home',
      roles: ['coach']
    }
  }, {
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/client/index');
    },
    name: 'Dashboard',
    meta: {
      title: 'Tableau de bord',
      affix: true,
      icon: 'home',
      roles: ['client']
    }
  }, {
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/admin/index');
    },
    name: 'Dashboard',
    meta: {
      title: 'Tableau de bord',
      affix: true,
      icon: 'home',
      roles: ['admin']
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/users',
  meta: {
    roles: ['admin']
  },
  children: [{
    path: 'users',
    component: function component() {
      return import('@/views/user/index');
    },
    name: 'users',
    meta: {
      title: 'Gestion des utilisateurs',
      icon: 'user'
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/taxDocuments',
  meta: {
    roles: ['admin']
  },
  children: [{
    path: 'taxDocuments',
    component: function component() {
      return import('@/views/taxDocuments/admin/index');
    },
    name: 'taxDocuments',
    meta: {
      title: 'Attestations fiscales',
      icon: 'tax'
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/invoices',
  meta: {
    roles: ['admin']
  },
  children: [{
    path: 'invoices',
    component: function component() {
      return import('@/views/documents/admin/invoices/index');
    },
    name: 'invoices',
    meta: {
      title: 'Factures',
      icon: 'invoice'
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/payment/:id',
  hidden: true,
  meta: {
    roles: ['client']
  },
  children: [{
    path: 'payment/:id',
    component: function component() {
      return import('@/views/payment/invoice');
    },
    name: 'payment',
    meta: {
      title: 'Paiement sécurisée',
      icon: 'invoice'
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/payment-success/:id',
  hidden: true,
  meta: {
    roles: ['client']
  },
  children: [{
    path: 'payment-success/:id',
    component: function component() {
      return import('@/views/payment/payment_success');
    },
    name: 'payment-success',
    meta: {
      title: 'Paiement validé'
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/automatic-debit-payment/:debit_id',
  hidden: true,
  meta: {
    roles: ['client']
  },
  children: [{
    path: 'automatic-debit-payment/:debit_id',
    component: function component() {
      return import('@/views/payment/payment_automatic_debit');
    },
    name: 'automatic-debit-payment',
    meta: {
      title: 'Prélèvement automatique'
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/search-customer/:id',
  meta: {
    exactPath: '/search-customer/:id',
    roles: ['admin']
  },
  hidden: true,
  children: [{
    path: 'search-customer/:id',
    component: function component() {
      return import('@/views/user/search/customer');
    },
    name: 'search-customer',
    meta: {
      title: 'Rechercher un client',
      icon: 'peoples'
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/sms',
  meta: {
    roles: ['admin']
  },
  children: [{
    path: 'sms',
    component: function component() {
      return import('@/views/sms/index');
    },
    name: 'SMS',
    meta: {
      title: 'Sms',
      icon: 'sms'
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/exports',
  meta: {
    roles: ['admin']
  },
  children: [{
    path: 'exports',
    component: function component() {
      return import('@/views/exports/index');
    },
    name: 'Exports',
    meta: {
      title: 'Exports',
      icon: 'sms'
    }
  }]
}, {
  path: '/',
  component: Layout,
  name: 'messages',
  meta: {
    exactPath: '/messages',
    roles: ['client', 'coach']
  },
  children: [{
    path: 'messages',
    name: 'Mes messages',
    meta: {
      title: 'Mes messages',
      roles: ['client'],
      icon: 'message'
    },
    component: function component() {
      return import('@/views/messages/client/index');
    }
  }, {
    path: 'messages',
    name: 'Mes messages',
    meta: {
      title: 'Mes messages',
      roles: ['coach'],
      icon: 'message'
    },
    component: function component() {
      return import('@/views/messages/coach/index');
    }
  }]
}, {
  path: '/',
  component: Layout,
  name: 'newmessage',
  hidden: true,
  meta: {
    exactPath: '/newmessage',
    roles: ['client', 'coach']
  },
  children: [{
    path: 'newmessage',
    name: 'New message',
    meta: {
      title: 'Nouveau message',
      roles: ['coach']
    },
    component: function component() {
      return import('@/views/messages/coach/newMessage');
    }
  }]
}, {
  path: '/',
  component: Layout,
  hidden: true,
  meta: {
    exactPath: '/conversation/:id',
    roles: ['client', 'coach']
  },
  children: [{
    path: 'conversation/:id',
    name: 'conversation',
    meta: {
      title: 'Conversation'
    },
    component: function component() {
      return import('@/views/messages/conversation');
    }
  }]
}, {
  path: '/',
  component: Layout,
  meta: {
    exactPath: '/clients',
    roles: ['coach']
  },
  children: [{
    path: 'clients',
    name: 'clients',
    meta: {
      title: 'Mes clients',
      icon: 'user'
    },
    component: function component() {
      return import('@/views/clients/index');
    }
  }, {
    path: 'clients/:id',
    hidden: true,
    name: 'client',
    meta: {
      title: 'Fiche client',
      icon: 'user'
    },
    component: function component() {
      return import('@/views/clients/client');
    }
  }]
}, {
  path: '/',
  component: Layout,
  name: 'sessions',
  meta: {
    exactPath: '/sessions',
    roles: ['client']
  },
  children: [{
    path: 'sessions',
    name: 'Mes séances',
    meta: {
      title: 'Mes séances',
      icon: 'session'
    },
    component: function component() {
      return import('@/views/sessions/clientCalendar');
    }
  }, {
    path: 'sessions/:id',
    hidden: true,
    name: 'sessioncard',
    meta: {
      title: 'Fiche séance',
      icon: 'invoice'
    },
    component: function component() {
      return import('@/views/sessions/sessioncard');
    }
  }]
}, {
  path: '/',
  component: Layout,
  name: 'calendar',
  meta: {
    exactPath: '/calendar',
    roles: ['coach'],
    icon: 'calendar'
  },
  children: [{
    path: 'calendar',
    name: 'Mon agenda',
    meta: {
      title: 'Mon agenda',
      icon: 'calendar'
    },
    component: function component() {
      return import('@/views/sessions/coachCalendar');
    }
  }, {
    path: 'sessions/:id',
    hidden: true,
    name: 'coachsessioncard',
    meta: {
      title: 'Fiche séance'
    },
    component: function component() {
      return import('@/views/sessions/coachsessioncard');
    }
  }]
}, {
  path: '/',
  component: Layout,
  hidden: true,
  meta: {
    exactPath: '/newSession',
    roles: ['coach']
  },
  children: [{
    path: 'newSession',
    name: 'newSession',
    meta: {
      title: 'Programmer une séance',
      roles: ['coach']
    },
    component: function component() {
      return import('@/views/sessions/newSession');
    }
  }]
}, {
  path: '/',
  component: Layout,
  hidden: true,
  meta: {
    exactPath: '/editSession/:id',
    roles: ['coach']
  },
  children: [{
    path: 'editSession/:id',
    name: 'editSession',
    meta: {
      title: 'Modifier la séance',
      roles: ['coach']
    },
    component: function component() {
      return import('@/views/sessions/editSession');
    }
  }]
}, {
  path: '/',
  component: Layout,
  meta: {
    exactPath: '/progression',
    roles: ['client']
  },
  children: [{
    path: 'progression',
    name: 'clientProgression',
    meta: {
      title: 'Ma progression sportive',
      icon: 'chart1'
    },
    component: function component() {
      return import('@/views/progession/index');
    }
  }]
}, {
  path: '/',
  component: Layout,
  meta: {
    exactPath: '/evolution',
    roles: ['client']
  },
  children: [{
    path: 'evolution',
    name: 'clientEvolution',
    meta: {
      title: 'Mon évolution corporelle',
      icon: 'body'
    },
    component: function component() {
      return import('@/views/evolution/index');
    }
  }]
}, {
  path: '/',
  component: Layout,
  meta: {
    exactPath: '/documents',
    roles: ['coach', 'client']
  },
  children: [{
    path: 'invoices',
    component: function component() {
      return import('@/views/documents/coach/invoices/index');
    },
    meta: {
      title: 'Mes factures',
      roles: ['coach'],
      icon: 'invoice'
    },
    name: 'invoices'
  }, {
    path: 'invoices',
    component: function component() {
      return import('@/views/documents/client/invoice');
    },
    meta: {
      title: 'Mes factures',
      roles: ['client'],
      icon: 'invoice'
    },
    name: 'invoices'
  }]
}, {
  path: '/',
  component: Layout,
  meta: {
    exactPath: '/documents',
    roles: ['coach', 'client']
  },
  children: [{
    path: 'taxDocuments',
    component: function component() {
      return import('@/views/taxDocuments/coach/index');
    },
    name: 'taxDocuments',
    meta: {
      title: 'Mes attestations fiscales',
      roles: ['coach'],
      icon: 'tax'
    }
  }, {
    path: 'tax',
    component: function component() {
      return import('@/views/taxDocuments/client/index');
    },
    meta: {
      title: 'Mes attestations fiscales',
      roles: ['client'],
      icon: 'invoice'
    },
    name: 'tax'
  }]
}, {
  path: '/',
  component: Layout,
  meta: {
    exactPath: '/documents',
    roles: ['client']
  },
  children: [{
    path: 'contracts',
    component: function component() {
      return import('@/views/documents/client/contract');
    },
    meta: {
      title: 'CGV',
      roles: ['client'],
      icon: 'contract'
    },
    name: 'contracts'
  }]
}, {
  path: '/',
  component: Layout,
  name: 'account',
  meta: {
    exactPath: '/account',
    roles: ['coach']
  },
  children: [{
    path: 'account',
    name: 'Mon compte',
    meta: {
      title: 'Mon compte',
      roles: ['coach'],
      icon: 'profile'
    },
    component: function component() {
      return import('@/views/account/coach/index');
    }
  }]
}, {
  path: '/',
  component: Layout,
  name: 'account',
  meta: {
    exactPath: '/account',
    roles: ['client']
  },
  children: [{
    path: 'account',
    name: 'Mon compte',
    meta: {
      title: 'Mon compte',
      roles: ['client'],
      icon: 'profile'
    },
    component: function component() {
      return import('@/views/account/client/index');
    }
  }]
}, {
  path: '/',
  component: Layout,
  hidden: true,
  meta: {
    exactPath: '/progression',
    roles: ['coach']
  },
  children: [{
    path: 'progression/:id',
    name: 'coachProgression',
    meta: {
      title: 'Progression sportive',
      icon: 'chart1'
    },
    component: function component() {
      return import('@/views/progession/index');
    }
  }]
}, {
  path: '/',
  component: Layout,
  hidden: true,
  meta: {
    exactPath: '/evolution/:id',
    roles: ['coach']
  },
  children: [{
    path: 'evolution/:id',
    name: 'coachEvolution',
    meta: {
      title: 'évolution corporelle',
      icon: 'body'
    },
    component: function component() {
      return import('@/views/evolution/index');
    }
  }]
}, {
  path: '/',
  component: Layout,
  hidden: true,
  meta: {
    exactPath: '/coach/:id',
    roles: ['coach', 'client']
  },
  children: [{
    path: 'coach/:id',
    name: 'ficheCoach',
    meta: {
      title: 'Fiche coach',
      icon: 'body'
    },
    component: function component() {
      return import('@/views/coachs/coach');
    }
  }]
}, {
  path: '/',
  component: Layout,
  meta: {
    exactPath: '/faq',
    roles: ['admin', 'coach', 'client']
  },
  children: [{
    path: 'faq',
    component: function component() {
      return import('@/views/faq/admin/index');
    },
    name: 'faq',
    meta: {
      title: 'FAQ',
      roles: ['admin'],
      icon: 'faq'
    }
  }, {
    path: 'faq',
    component: function component() {
      return import('@/views/faq/coach/index');
    },
    name: 'faq',
    meta: {
      title: 'FAQ',
      roles: ['coach'],
      icon: 'faq'
    }
  }, {
    path: 'faq',
    component: function component() {
      return import('@/views/faq/client/index');
    },
    name: 'faq',
    meta: {
      title: 'FAQ',
      roles: ['client'],
      icon: 'faq'
    }
  }]
}, {
  path: '/',
  component: Layout,
  meta: {
    exactPath: '/improvement-idea',
    roles: ['coach', 'client']
  },
  children: [{
    path: 'improvement-idea',
    component: function component() {
      return import('@/views/improvement-idea/index');
    },
    name: 'improvement-idea',
    meta: {
      title: 'Une idée d’amélioration ?',
      icon: 'improvement'
    }
  }]
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;