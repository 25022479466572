import { login as _login, logout as _logout, getInfo as _getInfo, register as _register } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import router, { resetRouter } from '@/router';
var state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  certified: false,
  info: {}
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_INFO: function SET_INFO(state, info) {
    state.info = info;
  },
  SET_CERTIFIED: function SET_CERTIFIED(state, certified) {
    state.certified = certified;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var email = userInfo.email.trim();
    return new Promise(function (resolve, reject) {
      _login(email, userInfo.password).then(function (response) {
        var token = response.access_token;
        // sets token in cookies
        setToken(token);
        commit('SET_TOKEN', token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user login
  register: function register(_ref2, userInfo) {
    var commit = _ref2.commit;
    var email = userInfo.mail.trim();
    return new Promise(function (resolve, reject) {
      _register(userInfo.firstname, userInfo.lastname, email, userInfo.pass, userInfo.checkPass).then(function (response) {
        // const token = response.access_token;
        // // sets token in cookies
        // setToken(token);
        // commit("SET_TOKEN", token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      _getInfo(state.token).then(function (response) {
        var data = Object.assign({}, response);
        if (data.role) {
          commit('SET_ROLES', [data.role]);
        } else {
          reject('getInfo: role must be a non-null !');
        }
        delete data.role;
        commit('SET_INFO', data);
        commit('SET_NAME', data.firstname + ' ' + data.lastname);
        commit('SET_CERTIFIED', data.companie && data.companie.certified);
        data.profile ? commit('SET_AVATAR', data.profile.photoPath) : commit('SET_AVATAR', require('@/assets/user.svg'));
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref4) {
    var state = _ref4.state,
      dispatch = _ref4.dispatch;
    return new Promise(function (resolve, reject) {
      _logout(state.token, false).then(function () {
        dispatch('resetToken');
        resetRouter();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      resolve();
    });
  },
  // Dynamically modify permissions
  changeRoles: function changeRoles(_ref6, role) {
    var commit = _ref6.commit,
      dispatch = _ref6.dispatch;
    return new Promise(function (resolve) {
      var token = role + '-token';
      commit('SET_TOKEN', token);
      setToken(token);
      var _dispatch = dispatch('getInfo'),
        roles = _dispatch.roles;
      resetRouter();

      // generate accessible routes map based on roles
      var accessRoutes = dispatch('permission/generateRoutes', roles, {
        root: true
      });

      // dynamically add accessible routes
      router.addRoutes(accessRoutes);
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};